body {
  font-family: Space Mono, monospace;
}

.container {
  margin: 4rem auto 4rem auto;
  padding: 1rem;
  max-width: 64rem;
}

.header {
  margin-bottom: 4rem;
}

h1 {
  font-size: 2.25rem;
  font-weight: 700;
}

h2 {
  font-size: 1.875rem;
  color: #4a5568;
}

.navbar {
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  gap: 30px;
  margin-bottom: 4rem;
}

.navbar > * {
  color: #68d391;
  font-size: 1.25rem;
  text-decoration: underline;
}

/* about page */

.about-container {
  display: grid;
  gap: 2rem;
}

.about-container > * {
  color: #4a5568;
  font-size: 1.25rem;
}

.about-link {
  color: black;
  text-decoration: underline;
}

/* work page */

.project {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 2rem;
  border-bottom: 2px solid #e2e8f0;
  padding: 3rem 0 3rem 0;
}

.project:first-child {
  padding-top: 0;
}

.project:last-child {
  border-bottom: none;
}

.project-header {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.project-image {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}

.description {
  color: #4a5568;
  padding: 0.5rem 0 0.5rem 0;
  font-size: 1rem;
}

.list-container {
  margin-top: 2rem;
}

.chip-list {
  display: inline;
  padding: 0.25rem 0 0.25rem 0;
}

.chip {
  color: #4a5568;
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  margin: 0 0.5rem 0.5rem 0;
  font-size: 0.75rem;
  font-weight: 600;
  display: inline-block;
  border-radius: 9999px;
  background-color: #edf2f7;
}

/* skills page */

.skills-header {
  font-size: 1.25rem;
  font-weight: 700;
}

.skills-container {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.skills-chip {
  display: inline-block;
  color: #4a5568;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin: 0 0.5rem 0.5rem 0;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 9999px;
  background-color: #edf2f7;
}
